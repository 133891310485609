import * as React from 'react'

export type Flags = {
    [name: string]: boolean | number
}

export const context = React.createContext<Flags>({})

export function useFlag(name: string): boolean {
    const flags = React.useContext(context)
    return Boolean(flags[name])
}

type Props = {
    flags: Flags
    children: React.ReactElement
}

export function FlagProvider(props: Props): React.ReactElement {
    const { flags, children } = props
    return <context.Provider value={flags}>{children}</context.Provider>
}
