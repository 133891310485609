import * as React from 'react'

export function useClickOutside<T extends HTMLElement>(ref: React.RefObject<T>, handler: () => void): void {
    React.useEffect(
        function (): () => void {
            function listener(evt: MouseEvent): void {
                if (!ref.current || ref.current.contains(evt.target as Node)) {
                    return
                }
                handler()
            }

            document.addEventListener('click', listener)
            return (): void => document.removeEventListener('click', listener)
        },
        [ref.current, handler],
    )
}
