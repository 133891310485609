import * as React from 'react'
import { t, plural } from '@lingui/macro'
import { useLocalisation } from '../../i18n'
import { HeaderIcon } from '../header-icon'
import { Envelope } from '../icon'

type Props = {
    count: number
    id: string
}

export function Inbox(props: Props): React.ReactElement {
    const { count, id } = props
    const { i18n } = useLocalisation()

    const label = t(i18n)`
        ${plural(count, {
            one: '# unread message',
            other: '# unread messages',
        })}
    `

    return <HeaderIcon count={count} label={label} icon={Envelope} href='/messages' id={id} />
}
