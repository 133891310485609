import { NormalizedCacheObject } from '@apollo/client'
import { Config } from './config'

import { Language } from './lib/i18n'
import { Flags } from './lib/flags'
import { Currency } from './api/types'
import { DiscogsError } from './lib/components/error'

export type DiscogsData = {
    config: Config
    locale: Language
    currency: Currency
    data: NormalizedCacheObject
    error?: DiscogsError
    flags: Flags
    isImpersonated: boolean
}

declare global {
    /* eslint-disable @typescript-eslint/consistent-type-definitions */
    interface Window {
        __DISCOGS_DATA__: DiscogsData | undefined
    }
}

function read(): DiscogsData {
    if (!process.browser) {
        // @ts-expect-error: we only read the discogs data in the browser
        return null
    }

    if (!window.__DISCOGS_DATA__) {
        throw new Error('Could not read __DISCOGS_DATA__')
    }

    return window.__DISCOGS_DATA__
}

export default read()
