import { Messages } from '@lingui/core'

type Catalog = {
    plurals: (n: string | number, ord?: boolean | undefined) => string
    messages: Messages
}

export const catalogs = {
    de: (): Promise<Catalog> => import(/* webpackChunkName: "de" */ './de'),
    en: (): Promise<Catalog> => import(/* webpackChunkName: "en" */ './en'),
    es: (): Promise<Catalog> => import(/* webpackChunkName: "es" */ './es'),
    fr: (): Promise<Catalog> => import(/* webpackChunkName: "fr" */ './fr'),
    it: (): Promise<Catalog> => import(/* webpackChunkName: "it" */ './it'),
    ja: (): Promise<Catalog> => import(/* webpackChunkName: "ja" */ './ja'),
    ko: (): Promise<Catalog> => import(/* webpackChunkName: "ko" */ './ko'),
    'pt-BR': (): Promise<Catalog> => import(/* webpackChunkName: "pt_BR" */ './pt_BR'),
    ru: (): Promise<Catalog> => import(/* webpackChunkName: "ru" */ './ru'),
}
