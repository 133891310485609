import { FieldFunctionOptions } from '@apollo/client/cache'

import {
    Format,
    LabelRelationship,
    Release,
    Maybe,
    ReviewConnection,
    ReviewEdge,
    Price,
    ReleaseStatisticsConnection,
    Video,
} from './api/types'
import { TypedTypePolicies } from './api/helpers'

export const typePolicies: TypedTypePolicies = {
    Image: {
        keyFields: ['id'],
    },
    ImageInfo: {
        keyFields: ['sourceUrl'],
    },
    Release: {
        keyFields: ['discogsId'],
        fields: {
            lowestPrice: {
                merge: true,
            },
            formats: {
                merge(existing: Format[] = [], incoming: Format[] | undefined = undefined): Format[] {
                    if (!incoming) {
                        return existing
                    }

                    if (existing.length !== incoming.length) {
                        return incoming
                    }

                    return existing.map(function (format: Format, index: number): Format {
                        return {
                            ...format,
                            ...incoming[index],
                        }
                    })
                },
            },
            labels: {
                read(labels: LabelRelationship[] | undefined): LabelRelationship[] {
                    return labels ?? []
                },
                merge(
                    existing?: LabelRelationship[] | null,
                    incoming?: LabelRelationship[] | null,
                ): LabelRelationship[] {
                    if (!existing || existing.length === 0) {
                        return incoming ?? []
                    }

                    if (!incoming) {
                        return existing
                    }

                    return existing.map(function (label: LabelRelationship, index: number): LabelRelationship {
                        return {
                            ...label,
                            ...incoming[index],
                            label: {
                                ...label.label,
                                ...incoming[index].label,
                            },
                        }
                    })
                },
            },
            styles: {
                read(styles: string[] | undefined): string[] {
                    return styles ?? []
                },
            },
            reviews: {
                keyArgs: ['discogsId'],
                merge(existing: Maybe<ReviewConnection>, incoming: Maybe<ReviewConnection>): Maybe<ReviewConnection> {
                    if (!existing) {
                        return incoming
                    }

                    if (!incoming) {
                        return existing
                    }

                    return {
                        ...existing,
                        ...incoming,
                        edges: [...existing.edges, ...incoming.edges],
                    }
                },
                read(existing: Maybe<ReviewConnection>, options: FieldFunctionOptions): ReviewConnection | undefined {
                    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
                    const { args } = options
                    if (!existing) {
                        return undefined
                    }

                    if (args?.after && existing.edges) {
                        const idx = existing.edges.findIndex((el: ReviewEdge): boolean => el.cursor === args.after)
                        if (idx === -1) {
                            return undefined
                        }

                        if (existing.edges.length - idx < args.first) {
                            return undefined
                        }
                    }

                    return existing
                },
            },
        },
    },
    Track: {
        keyFields: ['id'],
    },
    SubTrack: {
        keyFields: ['id'],
    },
    MasterRelease: {
        keyFields: ['discogsId'],
        fields: {
            videos(existing: Maybe<Video[]>): Video[] {
                return existing ?? []
            },
            reviews: {
                keyArgs: ['discogsId'],
                merge(existing: Maybe<ReviewConnection>, incoming: Maybe<ReviewConnection>): Maybe<ReviewConnection> {
                    if (!existing) {
                        return incoming
                    }

                    if (!incoming) {
                        return existing
                    }

                    return {
                        ...existing,
                        ...incoming,
                        edges: [...existing.edges, ...incoming.edges],
                    }
                },
                read(existing: Maybe<ReviewConnection>, options: FieldFunctionOptions): ReviewConnection | undefined {
                    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
                    const { args } = options
                    if (!existing) {
                        return undefined
                    }

                    if (args?.after && existing.edges) {
                        const idx = existing.edges.findIndex((el: ReviewEdge): boolean => el.cursor === args.after)
                        if (idx === -1) {
                            return undefined
                        }

                        if (existing.edges.length - idx < args.first) {
                            return undefined
                        }
                    }

                    return existing
                },
            },
        },
    },
    Artist: {
        keyFields: ['discogsId'],
        fields: {
            reviews: {
                keyArgs: ['discogsId'],
                merge(existing: Maybe<ReviewConnection>, incoming: Maybe<ReviewConnection>): Maybe<ReviewConnection> {
                    if (!existing) {
                        return incoming
                    }

                    if (!incoming) {
                        return existing
                    }

                    const uniqueDataByCursor = [...existing.edges, ...incoming.edges].filter(
                        (edge, index, self) => index === self.findIndex((t) => t.cursor === edge.cursor),
                    )

                    return {
                        ...existing,
                        ...incoming,
                        edges: uniqueDataByCursor,
                    }
                },
                read(existing: Maybe<ReviewConnection>, options: FieldFunctionOptions): ReviewConnection | undefined {
                    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
                    const { args } = options
                    if (!existing) {
                        return undefined
                    }

                    if (args?.after && existing.edges) {
                        const idx = existing.edges.findIndex((el: ReviewEdge): boolean => el.cursor === args.after)
                        if (idx === -1) {
                            return undefined
                        }

                        if (existing.edges.length - idx < args.first) {
                            return undefined
                        }
                    }
                    return existing
                },
            },
            releases: {
                merge(existing: Format[] = [], incoming: Format[] | undefined = undefined): Format[] {
                    if (!incoming) {
                        return existing
                    }

                    if (existing.length !== incoming.length) {
                        return incoming
                    }

                    return {
                        ...existing,
                        ...incoming,
                    }
                },
            },
        },
    },
    Price: {
        merge(existing: Price | null | undefined, incoming: Price | null | undefined): Price | null | undefined {
            if (!existing && !incoming) {
                return null
            }

            const res = {
                ...incoming,
                ...existing,
            } as Price

            if (typeof res.amount !== 'number' || !res.currency) {
                // @ts-expect-error
                res.amount = res['converted({"toCurrency":"USD"})']?.amount
                // @ts-expect-error
                res.currency = res['converted({"toCurrency":"USD"})']?.currency
            }

            return res
        },
    },
    Query: {
        fields: {
            release: {
                merge(
                    existing: Maybe<Release>,
                    incoming: Maybe<Release>,
                    options: FieldFunctionOptions,
                ): Maybe<Release> {
                    const { mergeObjects } = options
                    if (!incoming && existing) {
                        return existing
                    }
                    if (incoming && !existing) {
                        return incoming
                    }

                    // @ts-expect-error
                    return mergeObjects(existing, incoming)
                },
            },
        },
    },
    ReleaseStatisticsConnection: {
        merge(
            a: Maybe<ReleaseStatisticsConnection>,
            b: Maybe<ReleaseStatisticsConnection>,
        ): Maybe<ReleaseStatisticsConnection> {
            if (!a) {
                return b
            }
            return { ...a, ...b }
        },
    },
    ImagesConnection: {
        merge: true,
    },
    User: {
        merge: true,
        fields: {
            lists: {
                keyArgs: ['catalogItem'],
            },
        },
    },
    Review: {
        keyFields: ['discogsId'],
    },
    CuratedList: {
        keyFields: ['discogsId'],
    },
    CollectionItem: {
        keyFields: ['discogsId'],
    },
    CollectionNote: {
        keyFields: ['discogsId'],
    },
    CollectionNoteType: {
        keyFields: ['discogsId'],
    },
    CollectionFolder: {
        keyFields: ['discogsId'],
    },
    ListItem: {
        keyFields: ['discogsId'],
        fields: {
            comment: {
                merge(_, incoming) {
                    return incoming
                },
            },
        },
    },
    WantlistItem: {
        keyFields: ['discogsId'],
        merge: true,
    },
    Label: {
        keyFields: ['discogsId'],
        fields: {
            reviews: {
                keyArgs: ['discogsId'],
                merge(existing: Maybe<ReviewConnection>, incoming: Maybe<ReviewConnection>): Maybe<ReviewConnection> {
                    if (!existing) {
                        return incoming
                    }

                    if (!incoming) {
                        return existing
                    }

                    return {
                        ...existing,
                        ...incoming,
                        edges: [...existing.edges, ...incoming.edges],
                    }
                },
                read(existing: Maybe<ReviewConnection>, options: FieldFunctionOptions): ReviewConnection | undefined {
                    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
                    const { args } = options
                    if (!existing) {
                        return undefined
                    }

                    if (args?.after && existing.edges) {
                        const idx = existing.edges.findIndex((el: ReviewEdge): boolean => el.cursor === args.after)
                        if (idx === -1) {
                            return undefined
                        }

                        if (existing.edges.length - idx < args.first) {
                            return undefined
                        }
                    }

                    return existing
                },
            },
            releases: {
                merge(existing: Format[] = [], incoming: Format[] | undefined = undefined): Format[] {
                    if (!incoming) {
                        return existing
                    }

                    if (existing.length !== incoming.length) {
                        return incoming
                    }

                    return {
                        ...existing,
                        ...incoming,
                    }
                },
            },
        },
    },
}
