import * as React from 'react'
import classNames from 'classnames'

import { Close, InfoCircle, ExclamationCircle } from '../icon'
import { Content } from '../content'
import css from './styles.css'

type Props = {
    error?: boolean
    onDismiss?: () => void
    children: React.ReactNode
}

export function Banner(props: Props): React.ReactElement | null {
    const { children, onDismiss, error } = props

    const classnames = classNames(css.banner, onDismiss && css.dismissable, error && css.error)
    const dismiss = onDismiss && (
        <button aria-label='close' onClick={onDismiss} className={css.dismiss}>
            <Close />
        </button>
    )

    const icon = error ? <ExclamationCircle className={css.icon} /> : <InfoCircle className={css.icon} />

    return (
        <div className={classnames}>
            <Content className={css.content}>
                {icon}
                <span>{children}</span>
            </Content>
            {dismiss}
        </div>
    )
}
