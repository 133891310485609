import * as React from 'react'

export type DataLayerEvent = {
    event: string
    [k: string]: unknown
}

export type DataLayer = DataLayerEvent[]

declare global {
    /* eslint-disable @typescript-eslint/consistent-type-definitions */
    interface Window {
        dataLayer?: DataLayer
    }
}

type DataLayerContext = {
    push: (evt: DataLayerEvent) => void
}

const context = React.createContext<DataLayerContext | null>(null)

type ProviderProps = {
    dataLayer: DataLayer
    children: React.ReactNode
}

export function DataLayerProvider(props: ProviderProps): React.ReactElement {
    const { dataLayer, children } = props

    const value = React.useMemo(
        () => ({
            push(evt: DataLayerEvent): void {
                dataLayer.push(evt)
            },
        }),
        [],
    )

    return <context.Provider value={value}>{children}</context.Provider>
}

export function track(event: DataLayerEvent): void {
    window.dataLayer = window.dataLayer ?? []
    window.dataLayer.push(event)
}

type Options = {
    event: DataLayerEvent
    ssr?: boolean
    skip?: boolean
}

export function useDataLayer(options: Options): null {
    const { event, ssr = false, skip = false } = options
    const ctx = React.useContext(context)
    if (!ctx) {
        throw new Error(`DataLayerProvider not found`)
    }

    // TODO: only push once on the server
    if (ssr && !skip) {
        ctx.push(event)
    }

    React.useEffect(function () {
        if ((skip || ssr) && !process.isDevWatching) {
            return
        }

        track(event)
    }, [])

    return null
}
