import * as React from 'react'
import { useLocation, useHistory } from 'react-router'

export function RedirectLegacyImages(): null {
    const history = useHistory()
    const location = useLocation()

    React.useEffect(
        function (): void {
            if (!location.hash || !location.hash.startsWith('#images/')) {
                return
            }
            const id = location.hash.replace('#images/', '')
            history.push({
                ...location,
                pathname: `${location.pathname}/image/${id}`,
                hash: '',
            })
        },
        [location],
    )

    return null
}
