import * as React from 'react'

import Cookies from 'universal-cookie'
import { CookieSetOptions } from 'universal-cookie/es6/types'

type CookieContext = {
    cookies: Cookies
}

const context = React.createContext<CookieContext | null>(null)

type Setter = (value: string | undefined) => void

const year = 365 * 24 * 60 * 60

const domain = process.browser && window.location.host === 'www.discogs.com' ? '.www.discogs.com' : undefined

const defaults = {
    path: '/',
    maxAge: year,
    expires: new Date(Date.now() + year * 1000),
    sameSite: 'strict' as const,
    domain,
}

export function useCookie(name: string, options: CookieSetOptions = {}): [string | undefined, Setter] {
    const ctx = React.useContext(context)
    if (!ctx) {
        throw new Error('Cannot find CookieProvider')
    }

    const { cookies } = ctx
    const [value, setValue] = React.useState(cookies.get(name, { doNotParse: true }))

    function set(value: string | undefined): void {
        setValue(value)
    }

    React.useEffect(function (): () => void {
        function handler(update: { name: string; value?: string }): void {
            if (name !== update.name) {
                return
            }
            setValue(update.value)
        }

        cookies.addChangeListener(handler)
        return (): void => cookies.removeChangeListener(handler)
    }, [])

    React.useEffect(
        function () {
            if (value) {
                cookies.set(name, value, {
                    ...defaults,
                    ...options,
                })
            } else {
                cookies.remove(name)
            }
        },
        [value],
    )

    return [value, set]
}

type Props = {
    cookie: string
    children: React.ReactElement
}

export function CookieProvider(props: Props): React.ReactElement {
    const { cookie: header, children } = props

    const value = React.useMemo(
        function () {
            return {
                cookies: new Cookies(header),
            }
        },
        [header],
    )

    return <context.Provider value={value}>{children}</context.Provider>
}
