import { Language as UserLanguage } from '../login'
import { Language } from './language'

export const apiToLanguage: Record<UserLanguage, Language> = {
    [UserLanguage.German]: 'de',
    [UserLanguage.English]: 'en',
    [UserLanguage.French]: 'fr',
    [UserLanguage.Spanish]: 'es',
    [UserLanguage.Italian]: 'it',
    [UserLanguage.Japanese]: 'ja',
    [UserLanguage.Korean]: 'ko',
    [UserLanguage.PortugueseBr]: 'pt-BR',
    [UserLanguage.Russian]: 'ru',
} as const

// @ts-expect-error: We're still building this
export const languageToApi: Record<Language, UserLanguage> = {}
for (const userLanguage in apiToLanguage) {
    const language = apiToLanguage[userLanguage as UserLanguage]
    languageToApi[language] = userLanguage as UserLanguage
}
