import * as React from 'react'
import classnames from 'classnames'
import { t, Trans } from '@lingui/macro'

import { useLocalisation } from '../../i18n'
import { useMediaQuery } from '../../use-media-query'
import { AutocompleteResult, Category } from '../../../services/autocomplete'
import { WithProps } from '../../add-state'
import { enter, up, down, forwardSlash } from '../../keycodes'
import { useShortcut } from '../../components/shortcuts'
import { Link } from '../link'
import { Skittle } from '../skittle'
import { Search as SearchIcon, Spinner } from '../icon'

import css from './styles.css'

import { Props, State, Action } from '.'

type UIProps = WithProps<Props, State, Action>

export function SearchUI(props: UIProps): React.ReactElement {
    const { base, value, focus, working, category, results, index, dispatch, className, children } = props
    const { i18n } = useLocalisation()

    const input = React.useRef<HTMLInputElement>(null)

    useShortcut([forwardSlash], 'action', <Trans>Focus on Search</Trans>, function () {
        if (input.current) {
            input.current.focus()
        }
    })

    function onChange(evt: React.ChangeEvent<HTMLInputElement>): void {
        dispatch({ type: 'edit', value: evt.target.value })
    }

    function onFocus(): void {
        dispatch({ type: 'focus' })
    }

    function onBlur(): void {
        // Timeout is needed to make sure click on result works
        setTimeout(() => dispatch({ type: 'blur' }), 200)
    }

    function onMouseLeave(): void {
        dispatch({ type: 'select', index: -1 })
    }

    function onKeyDown(evt: React.KeyboardEvent<HTMLInputElement>): void {
        switch (evt.key) {
            case enter:
                // Enter
                evt.preventDefault()
                dispatch({ type: 'navigate' })
                break
            case up:
                // Up Arrow
                if (evt.shiftKey) {
                    return
                }

                evt.preventDefault()
                dispatch({ type: 'previous' })
                break
            case down:
                // Down Arrow
                if (evt.shiftKey) {
                    return
                }

                evt.preventDefault()
                dispatch({ type: 'next' })
                break
        }
    }

    const classname = classnames(css.search, className, {
        [css.focus]: focus,
        [css.working]: working,
    })

    const hidden = results === null || !focus

    const small = useMediaQuery('(min-width: 600px) and (max-width: 800px)')
    const label = t(i18n)`Search artists, albums and more...`
    const placeholder = small ? t(i18n)`Search...` : label
    const id = index === -1 ? '' : `ui-id-${index}`

    return (
        <>
            <form className={classname} action='/search' role='search' aria-label={t(i18n)`Search Discogs`}>
                <input
                    type='text'
                    name='q'
                    value={value}
                    placeholder={placeholder}
                    aria-label={label}
                    ref={input}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='none'
                    role='combobox'
                    aria-haspopup='listbox'
                    aria-expanded={!hidden}
                    aria-controls='quick_search_results'
                    aria-activedescendant={id}
                />
                <input type='hidden' name='type' value={category} />
                <Spinner className={css.spinner} aria-hidden='true' />
                <Status count={results?.length} />
                <div
                    className={css.dropdown}
                    onMouseLeave={onMouseLeave}
                    hidden={hidden}
                    aria-hidden={hidden}
                    id='quick_search_results'
                    role='listbox'
                    tabIndex={-1}
                    aria-label={t(i18n)`Search suggestions`}
                >
                    <Categories category={category} dispatch={dispatch} />
                    <Results results={results} index={index} base={base} dispatch={dispatch} />
                    <Advanced active={index === 'advanced'} />
                </div>
                <button type='submit' title={t(i18n)`Search`} className={css.submit} aria-label={t(i18n)`Search`}>
                    <SearchIcon className={css.icon} aria-hidden='true' />
                </button>
            </form>
            <span className={classnames(css.after, focus && css.focus)}>{children}</span>
        </>
    )
}

SearchUI.defaultProps = {
    category: Category.all,
    focus: false,
    results: null,
    working: false,
}

type AdvancedProps = {
    active: boolean
}

function Advanced(props: AdvancedProps): React.ReactElement {
    const { active } = props
    const classname = classnames(css.advanced, active && css.active)
    return (
        <Link href='/search/advanced' className={classname}>
            <SearchIcon aria-hidden='true' /> <Trans>Advanced Search</Trans>
        </Link>
    )
}

type StatusProps = {
    count?: number
}

function Status(props: StatusProps): React.ReactElement {
    const { count } = props

    return (
        <span role='status' aria-live='polite' aria-atomic='true' className={css.status}>
            {count && count > 0 && (
                <Trans>{count} results are available, use up and down arrow keys to navigate.</Trans>
            )}
        </span>
    )
}

type CategoriesProps = {
    category: Category
    dispatch: UIProps['dispatch']
}

function Categories(props: CategoriesProps): React.ReactElement {
    const { category, dispatch } = props
    const { i18n } = useLocalisation()

    type Defn = {
        cat: Category
        label: React.ReactElement
    }

    const categories: Defn[] = [
        { cat: Category.all, label: <Trans>Everything</Trans> },
        { cat: Category.release, label: <Trans>Releases</Trans> },
        { cat: Category.artist, label: <Trans>Artists</Trans> },
        { cat: Category.label, label: <Trans>Labels</Trans> },
    ]

    const onChange = (cat: Category): React.PointerEventHandler<HTMLButtonElement> =>
        function (evt: React.PointerEvent<HTMLButtonElement>): void {
            evt.preventDefault()
            dispatch({ type: 'category', category: cat })
        }

    return (
        <div className={css.categories} role='group' aria-label={t(i18n)`Search suggestions`}>
            {categories.map(
                ({ cat, label }: Defn): React.ReactElement => (
                    /* eslint-disable jsx-a11y/no-redundant-roles  */
                    <button
                        key={cat}
                        type='button'
                        role='button'
                        tabIndex={-1}
                        className={classnames(cat === category && css.active)}
                        aria-pressed={cat === category}
                        onPointerDown={onChange(cat)}
                    >
                        {label}
                    </button>
                ),
            )}
        </div>
    )
}

type ResultsProps = {
    index: number | 'advanced'
    results: null | AutocompleteResult[]
    base?: string
    dispatch: UIProps['dispatch']
}

function Results(props: ResultsProps): React.ReactElement | null {
    const { results, index, base, dispatch } = props

    if (results === null) {
        return null
    }

    return (
        /* eslint-disable jsx-a11y/no-redundant-roles  */
        <ul className={css.results} role='list'>
            {results.map(
                (res: AutocompleteResult, idx: number): React.ReactElement => (
                    <ResultItem
                        key={res.uri}
                        active={idx === index}
                        base={base}
                        dispatch={dispatch}
                        index={idx}
                        {...res}
                    />
                ),
            )}
        </ul>
    )
}

type ResultItemProps = AutocompleteResult & {
    active: boolean
    base?: string
    dispatch: UIProps['dispatch']
    index: number
}

function ResultItem(props: ResultItemProps): React.ReactElement {
    const {
        uri,
        thumb,
        title,
        type,
        format,
        marketplace,
        artist,
        active,
        base = '',
        dispatch,
        index,
        collection,
        wantlist,
        inventory,
    } = props

    const classname = classnames(active && css.active)
    const url = `${base}${uri}`

    function onMouseEnter(): void {
        dispatch({ type: 'select', index })
    }

    function handleMouseDown(evt: React.MouseEvent<HTMLAnchorElement>): void {
        if (evt.metaKey || evt.ctrlKey || evt.button === 2) {
            // Prevent blur on the input
            evt.preventDefault()
        }
    }

    // TODO: this code should be temporary
    // Remove this when we are confident that collection, wantlist, and inventory
    // will be numbers and not arrays
    function arrayToNum(numOrArray: number | number[] | undefined) {
        if (typeof numOrArray === 'undefined') {
            return null
        } else if (typeof numOrArray === 'number') {
            return numOrArray
        }
        return numOrArray.length
    }

    const userCollectionCount = arrayToNum(collection)
    const userWantlistCount = arrayToNum(wantlist)
    const userInventoryCount = arrayToNum(inventory)

    return (
        <li>
            <Link
                href={url}
                tabIndex={-1}
                id={`ui-id-${index}`}
                className={classname}
                onMouseEnter={onMouseEnter}
                onMouseDown={handleMouseDown}
            >
                <img className={css.thumbnail} src={thumb} alt={title} />
                <div className={css.info}>
                    <div className={css.skittles}>
                        {userCollectionCount && <Skittle type='collection' count={userCollectionCount} />}
                        {userWantlistCount && <Skittle type='wantlist' count={userWantlistCount} />}
                        {userInventoryCount && <Skittle type='inventory' count={userInventoryCount} />}
                    </div>
                    <span className={css.title}>
                        {title}{' '}
                        <span className={css.more}>
                            {type === 'Master Release' && <Trans>(all versions)</Trans>}
                            {format}
                        </span>
                    </span>
                    <span className={css.type}>
                        {type === 'Release' && artist}
                        {type !== 'Release' && type}
                    </span>
                    {marketplace && (
                        <span className={css.marketplace}>
                            <Trans>
                                {marketplace.quantity} from {marketplace.currency}
                            </Trans>
                            {marketplace.min.toFixed(2)}
                        </span>
                    )}
                </div>
            </Link>
        </li>
    )
}
