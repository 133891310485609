// This module contains the numerical key codes
// that match evt.which in keydown and keyup events.

// control keys
export const backspace = 'Backspace'
export const tab = 'Tab'
export const enter = 'Enter'
export const esc = 'Escape'
export const space = ' '
export const del = 46

// arrows
export const left = 'ArrowLeft'
export const up = 'ArrowUp'
export const right = 'ArrowRight'
export const down = 'ArrowDown'

// uppercase letters
export const A = 'A'
export const B = 'B'
export const C = 'C'
export const D = 'D'
export const E = 'E'
export const F = 'F'
export const G = 'G'
export const H = 'H'
export const I = 'I'
export const J = 'J'
export const K = 'K'
export const L = 'L'
export const M = 'M'
export const N = 'N'
export const O = 'O'
export const P = 'P'
export const Q = 'Q'
export const R = 'R'
export const S = 'S'
export const T = 'T'
export const U = 'U'
export const V = 'V'
export const W = 'W'
export const X = 'X'
export const Y = 'Y'
export const Z = 'Z'

// lowercase letters
export const a = 'a'
export const b = 'b'
export const c = 'c'
export const d = 'd'
export const e = 'e'
export const f = 'f'
export const g = 'g'
export const h = 'h'
export const i = 'i'
export const j = 'j'
export const k = 'k'
export const l = 'l'
export const m = 'm'
export const n = 'n'
export const o = 'o'
export const p = 'p'
export const q = 'q'
export const r = 'r'
export const s = 's'
export const t = 't'
export const u = 'u'
export const v = 'v'
export const w = 'w'
export const x = 'x'
export const y = 'y'
export const z = 'z'

// numbers
export const num0 = '0'
export const num1 = '1'
export const num2 = '2'
export const num3 = '3'
export const num4 = '4'
export const num5 = '5'
export const num6 = '6'
export const num7 = '7'
export const num8 = '8'
export const num9 = '9'

export const questionMark = '?'
export const forwardSlash = '/'
