import * as React from 'react'
import { Language } from './language'
import { localiseURL } from './localise-url'

export type Context = {
    language: Language
    languages: readonly Language[]
    changeLanguage(language: Language): void
    localiseURL: typeof localiseURL
    redirectedFrom: Language | null
}

export const context = React.createContext<Context | null>(null)

export function useLocalisation(): Context {
    const ctx = React.useContext(context)
    if (ctx) {
        return ctx
    }

    throw new Error('LocaleProvider not found')
}
