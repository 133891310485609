import { Location } from 'history'

import { Language, languages } from './language'

const placeholder = 'https://placeholder._'

/**
 * A helper to replace or add a locale to a URL.
 *
 * @example
 *     localiseURL('/en/foo', 'de') === '/de/foo'
 *     localiseURL('/foo', 'en') === '/foo'
 *     localiseURL('/bar', 'de') === '/de/bar'
 */
export function localiseURL(url: string, lang: Language): string
export function localiseURL(url: Location, lang: Language): Location
export function localiseURL(url: string | Location, lang: Language): string | Location {
    if (typeof url !== 'string') {
        return {
            ...url,
            pathname: localiseURL(url.pathname, lang),
        }
    }

    const u = new URL(url, 'https://placeholder._')
    const [prefix, ...rest] = u.pathname.split('/').filter((s: string): boolean => s !== '')

    const urlLang = lang.replace('-', '_')

    let touched = false
    for (const language of languages) {
        if (language.replace('-', '_') === prefix) {
            u.pathname = trim(`/${urlLang}/${rest.join('/')}`)
            touched = true
            break
        }
    }

    if (!touched) {
        u.pathname = trim(`/${urlLang}${u.pathname}`)
    }

    if (lang === 'en') {
        u.pathname = u.pathname.replace(/^\/en/, '')
    }

    if (u.pathname === '') {
        u.pathname = '/'
    }

    return u.href.replace(placeholder, '')
}

function trim(str: string): string {
    return str.replace(/\/*$/, '')
}
