/* eslint-disable no-console */

import 'regenerator-runtime/runtime'
import './webpack'

import * as React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'

import { App } from '../app'
import { Provider } from '../provider'

import { apollo } from './apollo'
import { WithLocale, init as initI18n } from './localisation'

import { initialize as datadog, ErrorBoundary } from './datadog'
import dsdata from '../discogs-data'

import { ErrorPage } from '../pages/error'
import { FatalErrorPage } from '../pages/fatal'

function handleError(err: Error, info?: React.ErrorInfo): void {
    console.error('[APP ERROR]', err)
}

void datadog()

async function init(): Promise<void> {
    console.log('initializing...')
    const { error, flags, currency, locale, isImpersonated } = dsdata

    await loadableReady()
    const i18n = await initI18n(locale)

    // load the client
    const client = apollo({ onError: handleError })

    type Props = {
        children?: React.ReactNode
    }

    function LocaleProvider(props: Props): React.ReactElement {
        return <WithLocale i18n={i18n} {...props} />
    }

    const component = (
        <ErrorBoundary fallback={FatalErrorPage}>
            <Provider
                flags={flags}
                cookie={document.cookie}
                client={client}
                currency={currency}
                error={error}
                router={BrowserRouter}
                localeProvider={LocaleProvider}
                isImpersonated={isImpersonated}
            >
                <ErrorBoundary fallback={ErrorPage}>
                    <App />
                </ErrorBoundary>
            </Provider>
        </ErrorBoundary>
    )

    const container = document.getElementById('app') as HTMLElement
    if (process.isDevWatching) {
        const root = ReactDOMClient.createRoot(container)
        root.render(component)
    } else {
        const root = ReactDOMClient.hydrateRoot(container, component)
        root.render(component)
    }
}

init().catch(handleError)
