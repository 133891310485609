import { addState, WithProps } from '../../add-state'
import { Icon } from '../icon'

import { HeaderIconUI } from './ui'

type Props = {
    className?: string
    count?: number
    href?: string
    label: string
    icon: Icon
    id: string
    expanded?: boolean
    onClick?: () => void
}

type State = {
    open: boolean
}

type Action = { type: 'open' } | { type: 'close' }

export type UIProps = WithProps<Props, State, Action>

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'open':
            return { ...state, open: true }
        case 'close':
            return { ...state, open: false }
        default:
            return state
    }
}

const initial = {
    open: false,
}

export const HeaderIcon = addState(HeaderIconUI, reducer, initial)
