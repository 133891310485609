import { DocumentNode, OperationDefinitionNode, DefinitionNode } from 'graphql'

type Hashes = {
    [operationsName: string]: string | undefined
}

export function generateHash(hashes: Hashes) {
    return function (doc: DocumentNode): string {
        const operationDefinition = doc.definitions.find(isOperationDefinition)
        if (!operationDefinition) {
            throw new Error('Operation missing from graphql query')
        }

        const name = operationDefinition.name?.value
        if (!name) {
            throw new Error('name missing from operation definition')
        }

        const hash = hashes[name]
        if (!hash) {
            throw new Error(`Cannot find pregenerated hash for ${name}`)
        }

        return hash
    }
}

function isOperationDefinition(defn: DefinitionNode): defn is OperationDefinitionNode {
    return defn.kind === 'OperationDefinition'
}
