import * as React from 'react'
import classnames from 'classnames'
import { Trans, t } from '@lingui/macro'

import { useLocalisation } from '../../i18n'
import { usePersist } from '../../use-persist'
import { ErrorBoundary } from '../error-boundary'
import { useLogin } from '../../login'
import { useMediaQuery } from '../../use-media-query'
import { usePrevious } from '../../use-previous'

import { ChevronDown } from '../icon'

import { Props } from '.'

import css from './styles.css'

export const SectionUI = React.forwardRef(function SectionUI(
    props: Props,
    ref: React.Ref<HTMLElement>,
): React.ReactElement {
    // We need to be able to add an id for aria-controls here.
    // TODO: reenable aria-controls and add id to .content once React.useOpaqueIdentifier lands.

    const { children, className, collapsible, id, isOpenByDefault = false } = props
    const { i18n } = useLocalisation()

    const header = React.useRef<HTMLElement>(null)
    const [collapsed, setCollapsed] = usePersist(`section-collapse-${id}`)
    const mobile = useMediaQuery('(max-width: 600px)')

    const { loggedIn } = useLogin()
    const canCollapse = id && loggedIn && (collapsible === undefined ? mobile : collapsible)
    const open = !canCollapse || (collapsed === undefined ? isOpenByDefault : !collapsed)
    const prev = usePrevious(open)

    React.useEffect(
        function () {
            if (!header.current || open || open === prev || window.innerWidth > 600) {
                return
            }

            // on closing, make sure header is still visible
            const rect = header.current.getBoundingClientRect()
            if (rect.top < 0) {
                header.current.scrollIntoView({
                    inline: 'start',
                })
            }
        },
        [open],
    )

    function handleToggle(): void {
        if (!canCollapse) {
            return
        }

        setCollapsed(collapsed === undefined ? isOpenByDefault : !collapsed)
    }

    const classname = classnames(
        css.section,
        className,
        open && css.open,
        canCollapse && css.collapsible,
        mobile && css.mobile,
    )

    const head = 'title' in props ? <h2>{props.title}</h2> : props.header

    const toggle = canCollapse && (
        <button
            className={css.toggle}
            title={t(i18n)`Toggle section`}
            onClick={handleToggle}
            aria-expanded={open}
            aria-controls={id}
            aria-label={open ? t(i18n)`Collapse section` : t(i18n)`Expand section`}
        >
            <ChevronDown aria-hidden='true' />
        </button>
    )

    function handleClick(evt: React.MouseEvent<HTMLElement>): void {
        if (!canCollapse || !header.current) {
            return
        }

        let el: HTMLElement | null = evt.target as HTMLElement
        for (;;) {
            if (!el) {
                return
            }

            if (el.tagName === 'A' || el.tagName === 'BUTTON') {
                return
            }

            if (el === header.current) {
                break
            }

            if (el.getAttribute('role') === 'button') {
                return
            }

            if (el.tagName === 'SECTION' && el.id === id) {
                break
            }

            el = el.parentNode as HTMLElement | null
        }

        handleToggle()
    }

    return (
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        <section id={id} className={classname} ref={ref}>
            <header className={css.header} onClick={handleClick} ref={header}>
                {head}
                {toggle}
            </header>
            <div className={css.content} hidden={Boolean((collapsible || mobile) && !open)}>
                <ErrorBoundary fallback={Fallback}>{children}</ErrorBoundary>
            </div>
        </section>
    )
})

function Fallback(): React.ReactElement {
    return <Trans>Something went wrong in this section.</Trans>
}
