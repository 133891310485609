import { useLocalisation as useContext, Context } from './context'
import { useLingui, I18nContext } from '@lingui/react'

export type LocaleContext = I18nContext & Context

export function useLocalisation(): LocaleContext {
    return {
        ...useContext(),
        ...useLingui(),
    }
}
