import * as React from 'react'
import { Meta } from 'react-head'

import { useCartCount } from '../../use-cart-count'
import { User } from '../../login'

import { HeaderNav } from '../header-nav'
import { Footer } from '../footer'
import { LanguageBanner } from '../language-banner'
import { Favicon } from '../favicon'
import { ErrorBanners } from '../error-banners'

import { config } from '../../../config'

import css from './styles.css'
import { ImpersonationBanner } from '../../../components/impersonation-banner'

type Props = {
    user?: User | null
    messageCount?: number | null
    cartCount?: number | null
    loading?: boolean
    children: React.ReactElement
}

export function Page(props: Props): React.ReactElement {
    const { SEARCH_URL = '' } = config
    const { children, user, loading, cartCount, messageCount } = props

    const cookieCartCount = useCartCount()
    const _cartCount = cartCount ?? cookieCartCount

    return (
        <>
            <Favicon />
            <Meta name='viewport' content='width=device-width, initial-scale=1' />
            <HeaderNav
                search={{ base: SEARCH_URL }}
                cart={typeof _cartCount === 'number' ? { count: _cartCount } : undefined}
                inbox={typeof messageCount === 'number' ? { count: messageCount } : undefined}
                user={user ?? undefined}
                loading={loading}
            />
            <ImpersonationBanner />
            <div className={css.content} id='page' role='main'>
                <LanguageBanner />
                <ErrorBanners />
                {children}
            </div>
            <Footer />
        </>
    )
}
