import * as React from 'react'

const key = 'discogs_cart'

export function useCartCount(): number {
    const [count, setCount] = React.useState<number>(0)

    React.useEffect(function (): void {
        try {
            const item = localStorage.getItem(key)
            if (!item) {
                return
            }
            const { cartItems = [] } = JSON.parse(item)
            setCount(cartItems?.length ?? 0)
        } catch (_) {
            // noop
        }
    })

    return count
}
