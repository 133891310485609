import * as React from 'react'
import { useMediaQuery as useMedia } from '@react-hook/media-query'

export function useMediaQuery(query: string, ssr: boolean = false): boolean {
    // It's ok to ignore the rules of hooks here since the conditional above is
    // stable (either *always* true or *always* false in a given context).
    /* eslint-disable react-hooks/rules-of-hooks */

    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    if (typeof window === 'undefined' || !window.matchMedia) {
        return false
    }

    const [value, setValue] = React.useState(false)
    const result = useMedia(query)
    React.useEffect(
        function () {
            setValue(result)
        },
        [result],
    )

    return value
}
