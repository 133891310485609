import { matchPath } from 'react-router'

import { languages, Language } from './language'

const urlLanguages = languages.map((language: Language): string => language.replace('-', '_'))
const path = `/:locale(${urlLanguages.join('|')})`

export function matchLocale(pathname: string): Language {
    const match = matchPath(pathname, {
        path,
        exact: false,
    })

    if (!match) {
        return 'en'
    }

    // @ts-expect-error
    return match.params.locale.replace('_', '-')
}
