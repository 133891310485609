import * as React from 'react'
import { Currency } from '../api/types'
import { useLogin } from './login'
import { useCookie } from './cookies'

const context = React.createContext<Currency>(Currency.Usd)

type Props = {
    currency: Currency
    children: React.ReactNode
}

export function CurrencyProvider(props: Props): React.ReactElement {
    const { currency, children } = props

    const { user } = useLogin()
    const [, setCookie] = useCookie('currency')

    React.useEffect(
        function () {
            if (!user) {
                return
            }
            setCookie(user.currency)
        },
        [user],
    )

    return <context.Provider value={user?.currency ?? currency}>{children}</context.Provider>
}

export function useCurrency(): Currency {
    return React.useContext(context)
}
