import * as React from 'react'
import { SectionUI } from './ui'

// Eslint and Prettier seem to disagree about spacing here.

type TitleOrHeader =
    | {
          title: React.ReactElement | string
          altAction?: React.ReactNode
      }
    | {
          header: React.ReactElement
      }

export type Props = {
    id: string
    className?: string
    children?: React.ReactNode
    collapsible?: boolean
    isOpenByDefault?: boolean
} & TitleOrHeader

export const Section = SectionUI
