export type Language = typeof languages[number]

export const languages = ['en', 'de', 'es', 'fr', 'it', 'ja', 'ko', 'pt-BR', 'ru'] as const

export const defaultLanguage: Language = 'en'

/**
 * Validate that str is a valid language code
 */
export function isLanguage(str: string): str is Language {
    return languages.includes(str as Language)
}

/**
 * Convert str to a valid language. If str is not a valid language code, returns defaultLanguage.
 */
export function asLanguage(str: string): Language {
    if (isLanguage(str)) {
        return str
    }

    return defaultLanguage
}
