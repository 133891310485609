import * as React from 'react'

import { Layout } from '../lib/components/layout'
import { Page } from '../lib/components/page'
import { ErrorMessage, DiscogsError } from '../lib/components/error'

export function ErrorPage(props: DiscogsError): React.ReactElement {
    return (
        <Layout>
            <Page>
                <ErrorMessage {...props} />
            </Page>
        </Layout>
    )
}
