import fetch from 'isomorphic-unfetch'

export type AutocompleteResult = {
    thumb: string
    uri: string
    inventory_url: string
    title: string
    type: 'Artist' | 'Master Release' | 'Release' | 'Label'
    artist?: string
    format?: string
    marketplace?: {
        currency: string
        quantity: number
        min: number
    }
    collection?: number[] | number
    wantlist?: number[] | number
    inventory?: number[] | number
}

type Options = {
    signal?: AbortSignal
    base?: string
}

export enum Category {
    all = 'all',
    release = 'release',
    artist = 'artist',
    label = 'label',
}

/**
 * Perform an autocomplete query.
 */
export async function autocomplete(
    query: string,
    category: Category = Category.all,
    opts: Options = {},
): Promise<AutocompleteResult[]> {
    const { base = '', signal } = opts

    const url = `${base}/search/ac?searchType=${category}&q=${encodeURIComponent(query)}`
    const resp = await fetch(url, {
        signal,
        credentials: 'include',
    })
    return resp.json()
}
