import * as React from 'react'
import { t } from '@lingui/macro'
import { NotificationBanner, NotificationBannerTypes } from '@discogs/amped/notification-banner'
import { AnchorButton } from '@discogs/amped/button'

import { useLogin } from '../../lib/login'
import { useLocalisation } from '../../lib/i18n'

import css from './styles.css'

export function ImpersonationBanner(): React.ReactElement | null {
    const login = useLogin()
    const { i18n } = useLocalisation()

    if (login.loading || !login.loggedIn || !login.isImpersonated) {
        return null
    }

    const unpersonate = 'https://www.discogs.com/unpersonate'

    return (
        <>
            <NotificationBanner
                bannerType={NotificationBannerTypes.WARNING}
                message={i18n._(t`You are impersonating ${login.user?.username}.`)}
                actionLinkText={i18n._(t`Stop impersonating`)}
                actionLinkSrc={unpersonate}
            />
            <div className={css.bottom}>
                <AnchorButton variant='primary' dense href={unpersonate}>
                    {i18n._(t`Stop impersonating ${login.user?.username}`)}
                </AnchorButton>
            </div>
        </>
    )
}
