import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import { Trans, t } from '@lingui/macro'
import FocusTrap from 'focus-trap-react'
import { useLocalisation } from '../../../lib/i18n'

import { useCookie } from '../../cookies'
import { Section } from '../section'
import { Close } from '../icon'
import { useBlockScroll } from '../../use-block-scroll'
import { useKeyPress } from '../../use-key-press'
import { useClickOutside } from '../../use-click-outside'
import { esc, c, d, f, g, i, m, o, p, r, s, u, v, w } from '../../keycodes'

import { useNavShortcut } from './use-nav-shortcut'
import { useContext, Shortcut } from './use-shortcut'

import css from './styles.css'

type UIProps = {
    open: boolean
    onClose: () => void
}

const classNames = {
    enter: css.enter,
    enterActive: css.enterActive,
    enterDone: css.enterDone,
    exit: css.exit,
    exitActive: css.exitActive,
    exitDone: css.exitDone,
}

function isAlpha(str: string): boolean {
    return /^[a-z]+$/.exec(str) !== null
}

function byKeys(a: Shortcut, b: Shortcut): number {
    if (isAlpha(a.shortcut.join('')) && !isAlpha(b.shortcut.join(''))) {
        return -1
    }
    if (!isAlpha(a.shortcut.join('')) && isAlpha(b.shortcut.join(''))) {
        return 1
    }
    return a.shortcut < b.shortcut ? -1 : 1
}

export function ShortcutsUI(props: UIProps): React.ReactElement | null {
    const { open, onClose } = props
    const ref = React.useRef<HTMLDivElement>(null)

    const { i18n } = useLocalisation()
    const { shortcuts } = useContext()
    useBlockScroll({ block: open })
    useClickOutside(ref, onClose)
    useKeyPress({
        [esc]: onClose,
    })

    const [keyboard_shortcuts = 'false', setCookie] = useCookie('keyboard_shortcuts')

    const shortcutCookie = Boolean(keyboard_shortcuts && keyboard_shortcuts !== 'false')

    useNavShortcut([g, f], <Trans>Go To Forum</Trans>, '/forum')
    useNavShortcut([g, g], <Trans>Go To Groups</Trans>, '/groups')
    useNavShortcut([g, d], <Trans>Go To Dashboard</Trans>, '/my')
    useNavShortcut([g, c], <Trans>Go To Collection</Trans>, '/user/{username}/collection')
    useNavShortcut([g, w], <Trans>Go To Wantlist</Trans>, '/mywantlist')
    useNavShortcut([g, s], <Trans>Go To Submissions</Trans>, '/submissions')
    useNavShortcut([g, p], <Trans>Go To Purchases</Trans>, '/sell/purchases')
    useNavShortcut([g, i], <Trans>Go To Inventory</Trans>, '/sell/manage')
    useNavShortcut([g, o], <Trans>Go To Orders</Trans>, '/sell/orders')
    useNavShortcut([g, m], <Trans>Go To Messages</Trans>, '/messages')
    useNavShortcut([g, u], <Trans>Go To User Profile</Trans>, '/user/{username}')

    // Forum
    useNavShortcut([f, r], <Trans>Go To Recent</Trans>, '/forum/recent', 'forum')
    useNavShortcut([f, p], <Trans>Go To Posted</Trans>, '/forum/posted', 'forum')
    useNavShortcut([f, s], <Trans>Go To Started</Trans>, '/forum/started', 'forum')
    useNavShortcut([f, v], <Trans>Go To Saved</Trans>, '/forum/saved', 'forum')
    useNavShortcut([f, w], <Trans>Go To Watched</Trans>, '/forum/watched', 'forum')

    const header = (
        <>
            <h1>
                <Trans>Keyboard Shortcuts</Trans>
            </h1>
            <button onClick={onClose} className={css.close}>
                <Close aria-label={t(i18n)`Close`} />
            </button>
        </>
    )

    const navigation = shortcuts.filter((shortcut: Shortcut): boolean => shortcut.category === 'nav').sort(byKeys)
    const actions = shortcuts.filter((shortcut: Shortcut): boolean => shortcut.category === 'action').sort(byKeys)
    const forum = shortcuts.filter((shortcut: Shortcut): boolean => shortcut.category === 'forum').sort(byKeys)

    return (
        <CSSTransition in={open} timeout={350} unmountOnExit mountOnEnter classNames={classNames}>
            <FocusTrap>
                <div className={css.wrapper}>
                    <div className={css.inner} ref={ref}>
                        <Section header={header} collapsible={false} id='shortcuts'>
                            <div className={css.shortcuts}>
                                <label htmlFor='enable-shortcuts'>
                                    <Trans>Enable Keyboard Shortcuts:</Trans>
                                </label>
                                <input
                                    type='checkbox'
                                    id='enable-shortcuts'
                                    checked={shortcutCookie}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                        setCookie(event.target.checked.toString())
                                    }
                                />
                            </div>
                            <div className={css.cols}>
                                <div className={css.col}>
                                    <h2>
                                        <Trans>Navigation</Trans>
                                    </h2>
                                    {/* eslint-disable jsx-a11y/no-redundant-roles */}
                                    <ul role='list'>
                                        {navigation.map(
                                            (shortcut: Shortcut): React.ReactElement => (
                                                <Info {...shortcut} key={shortcut.shortcut.join('.')} />
                                            ),
                                        )}
                                    </ul>
                                </div>
                                <div className={css.col}>
                                    <h2>
                                        <Trans>Actions</Trans>
                                    </h2>
                                    {/* eslint-disable jsx-a11y/no-redundant-roles */}
                                    <ul role='list'>
                                        {actions.map(
                                            (shortcut: Shortcut): React.ReactElement => (
                                                <Info {...shortcut} key={shortcut.shortcut.join('.')} />
                                            ),
                                        )}
                                    </ul>
                                </div>
                                <div className={css.col}>
                                    <h2>
                                        <Trans>Forum</Trans>
                                    </h2>
                                    {/* eslint-disable jsx-a11y/no-redundant-roles */}
                                    <ul role='list'>
                                        {forum.map(
                                            (shortcut: Shortcut): React.ReactElement => (
                                                <Info {...shortcut} key={shortcut.shortcut.join('.')} />
                                            ),
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </Section>
                    </div>
                </div>
            </FocusTrap>
        </CSSTransition>
    )
}

function Info(props: Shortcut): React.ReactElement {
    /* eslint-disable react/no-array-index-key */
    const { shortcut, title } = props

    const keys = Array.from(shortcut).map(
        (k: string, index: number): React.ReactElement => (
            <kbd className={css.key} key={`${k}${index}`}>
                {toKey(k)}
            </kbd>
        ),
    )

    return (
        <li>
            <span className={css.keys}>{keys}</span>
            {title}
        </li>
    )
}

function toKey(k: string): string {
    switch (k) {
        case ' ':
            return 'space'
        case 'Escape':
            return 'Esc'
        default:
            return k
    }
}
