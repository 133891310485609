import * as React from 'react'
import classnames from 'classnames'
import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useLocalisation } from '../../i18n'
import { Collection, Eye, Tag } from '../icon'

import css from './styles.css'

type Props = {
    count: number
    type: 'collection' | 'wantlist' | 'inventory'
    noHide?: boolean
}

const meta = {
    collection: {
        className: css.collection,
        message: (i18n: I18n, count: number): string => t(i18n)`${count} in Collection`,
        icon: <Collection aria-hidden='true' />,
    },
    wantlist: {
        className: css.wantlist,
        message: (i18n: I18n, count: number): string => t(i18n)`${count} in Wantlist`,
        icon: <Eye aria-hidden='true' />,
    },
    inventory: {
        className: css.inventory,
        message: (i18n: I18n, count: number): string => t(i18n)`${count} in Inventory`,
        icon: <Tag aria-hidden='true' />,
    },
}

export function Skittle(props: Props): React.ReactElement | null {
    const { type, count, noHide } = props
    const { i18n } = useLocalisation()

    if (count === 0 && !noHide) {
        return null
    }

    const info = meta[type]

    const classname = classnames(css.skittle, info.className)
    const label = info.message(i18n, count)
    const { icon } = info

    return (
        <span className={classname}>
            <span className={css.wrap}>
                <div
                    className={classnames(css.icon, count > 9 ? css.countTwoDigits : '')}
                    role='note'
                    aria-label={label}
                    tabIndex={0}
                >
                    {icon}
                </div>
                <span aria-hidden='true'>{count}</span>
                <div className={css.tooltip}>{label}</div>
            </span>
        </span>
    )
}
