import * as React from 'react'
import classnames from 'classnames'
import { I18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { useClickOutside } from '../../use-click-outside'
import { useKeyPress } from '../../use-key-press'
import { esc } from '../../keycodes'
import { WithProps } from '../../add-state'
import { useLocalisation } from '../../../lib/i18n'
import { trackNavigation } from '../../../lib/interactions'

import { CaretDown, Bars } from '../icon'
import { HeaderIcon } from '../header-icon'
import { Link } from '../link'
import { Button as ButtonComponent } from '../buttons'

import { Props, State, Action } from '.'
import { Category, Group, Item } from './types'

import css from './styles.css'

type UIProps = WithProps<Props, State, Action>

export function HeaderDropdownUI(props: UIProps): React.ReactElement {
    const { categories, category, mobileOpen, dispatch } = props
    const { i18n } = useLocalisation()

    const ref = React.createRef<HTMLDivElement>()
    useClickOutside<HTMLDivElement>(ref, function (): void {
        dispatch({ type: 'close-all' })
    })

    useKeyPress({
        [esc](): void {
            dispatch({ type: 'close-all' })
        },
    })

    function toggleMobile(): void {
        dispatch({ type: 'mobile-toggle' })
    }

    return (
        <nav className={css.wrapper} ref={ref} aria-label={t(i18n)`Main`}>
            <HeaderIcon
                icon={Bars}
                label={t(i18n)`Main Menu`}
                className={css.mobile}
                onClick={toggleMobile}
                id='mobile-menu'
                expanded={mobileOpen}
            />
            <div className={classnames(css.menu, mobileOpen && css.open)}>
                <ul className={css.categories}>
                    {categories.map(
                        (cat: Category, index: number): React.ReactElement => (
                            /* eslint-disable react/no-array-index-key */
                            <li className={css.categoriesItem} key={index}>
                                <CategoryDropdown
                                    active={index === category}
                                    category={cat}
                                    dispatch={dispatch}
                                    index={index}
                                />
                            </li>
                        ),
                    )}
                </ul>
            </div>
        </nav>
    )
}

type CategoryProps = {
    active: boolean
    category: Category
    index: number
    dispatch: UIProps['dispatch']
}

function CategoryDropdown(props: CategoryProps): React.ReactElement {
    const { active, category, index, dispatch } = props

    const classname = classnames(css.dropdown, active && css.active, category.align && css.align)

    return (
        <div className={classname}>
            <Button title={category.title} dispatch={dispatch} index={index} active={active} />
            <Groups {...category} active={active} />
        </div>
    )
}

type ButtonProps = {
    title: (i18n: I18n) => string
    toggle?: (i18n: I18n) => string
    index: number
    dispatch: UIProps['dispatch']
    active: boolean
}

function Button(props: ButtonProps): React.ReactElement {
    const { title, index, dispatch, active, toggle } = props
    const { i18n } = useLocalisation()

    function open(evt: React.MouseEvent<HTMLButtonElement>): void {
        if (evt.metaKey || evt.shiftKey || evt.ctrlKey) {
            // meta-click: just allow the link to open in new tab
            return
        }

        evt.preventDefault()
        if (active) {
            dispatch({ type: 'close' })
            return
        }

        dispatch({ type: 'open', category: index })
    }

    function focus(): void {
        if (window.innerWidth > 950) {
            dispatch({ type: 'focus', category: index })
        }
    }

    return (
        <ButtonComponent
            className={classnames(css.button, active && css.active)}
            onClick={open}
            color='nav'
            onMouseEnter={focus}
            aria-expanded={active}
            role='button'
            aria-label={toggle?.(i18n)}
        >
            {title(i18n)}
            <CaretDown className={classnames(css.caret, active && css.active)} aria-hidden='true' />
        </ButtonComponent>
    )
}

type GroupsProps = Category & {
    active: boolean
}

function Groups(props: GroupsProps): React.ReactElement {
    const { groups, active } = props

    const classname = classnames(css.groups, active && css.active)

    return (
        <div className={classname}>
            {groups.map(
                (group: Group, index: number): React.ReactElement => (
                    /* eslint-disable react/no-array-index-key */
                    <Tray {...group} key={index} />
                ),
            )}
        </div>
    )
}

function Tray(props: Group): React.ReactElement {
    const { title, items } = props
    const { i18n } = useLocalisation()

    const t =
        typeof title === 'object' && 'href' in title ? (
            <Link href={title.href}>{title.title(i18n)}</Link>
        ) : (
            title?.(i18n) ?? null
        )

    return (
        <ul className={css.group}>
            {t && <h2>{t}</h2>}
            <li>
                <ul>
                    {items.map(function (item: Item): React.ReactElement {
                        function handleClick(): void {
                            trackNavigation({ location: item.href })
                        }
                        const { href, title } = item
                        const t = title(i18n)
                        return (
                            <li key={href}>
                                <Link href={href} onClick={handleClick}>
                                    {t}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </li>
        </ul>
    )
}
