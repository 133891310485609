import * as React from 'react'
import classnames from 'classnames'
import { I18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'

import { useLocalisation } from '../../../lib/i18n'
import { WithProps } from '../../add-state'
import { useKeyPress } from '../../use-key-press'
import { useClickOutside } from '../../use-click-outside'
import { esc } from '../../keycodes'

import { Link } from '../link'

import {
    Icon,
    CaretDown,
    CaretUp,
    Dashboard,
    Collection,
    Wantlist,
    List,
    FileTextOpen,
    Pencil,
    User,
    QuestionCircle,
    Cog,
    CloudUpload,
    PowerOff,
} from '../icon'

import css from './styles.css'

import { Props, State, Action } from '.'

type UIProps = WithProps<Props, State, Action>

type Item = {
    href: string
    title: (i18n: I18n) => string
    icon: Icon
    admin?: boolean
}

type Spacer = 'spacer'
const spacer: Spacer = 'spacer'

const items = (username: string): (Item | Spacer)[] => [
    { title: (i18n) => t(i18n)`Dashboard`, icon: Dashboard, href: '/my' },
    { title: (i18n) => t(i18n)`Collection`, icon: Collection, href: `/user/${username}/collection` },
    { title: (i18n) => t(i18n)`Wantlist`, icon: Wantlist, href: '/mywantlist' },
    { title: (i18n) => t(i18n)`Lists`, icon: List, href: `/user/${username}/lists` },
    { title: (i18n) => t(i18n)`Submissions`, icon: FileTextOpen, href: '/submissions' },
    { title: (i18n) => t(i18n)`Drafts`, icon: Pencil, href: '/users/drafts' },
    spacer,
    { title: (i18n) => t(i18n)`Profile`, icon: User, href: `/user/${username}` },
    { title: (i18n) => t(i18n)`Help`, icon: QuestionCircle, href: 'https://support.discogs.com' },
    { title: (i18n) => t(i18n)`Settings`, icon: Cog, href: '/settings/user' },
    { title: (i18n) => t(i18n)`Media`, icon: CloudUpload, href: '/users/media', admin: true },
    { title: (i18n) => t(i18n)`Log Out`, icon: PowerOff, href: '/logout' },
]

export function UserUI(props: UIProps): React.ReactElement | null {
    const { profile, open, loading, dispatch, returnTo } = props
    const { i18n } = useLocalisation()
    const ref = React.createRef<HTMLDivElement>()

    useClickOutside(ref, function (): void {
        dispatch({ type: 'close' })
    })

    useKeyPress({
        [esc]() {
            dispatch({ type: 'close' })
        },
    })

    if (loading) {
        return (
            <div className={css.loading}>
                <div className={css.bezel} />
                <CaretDown className={css.caret} />
            </div>
        )
    }

    if (!profile) {
        const url = returnTo ? `/login?return_to=${encodeURIComponent(returnTo)}` : '/login'

        return (
            <div className={css.loggedout}>
                <Link href={url}>
                    <Trans>Log In</Trans>
                </Link>{' '}
                <Link href='/users/create' className={css.register}>
                    <Trans>Register</Trans>
                </Link>
            </div>
        )
    }

    const { username, avatarUrl } = profile

    const classname = classnames(css.user, open && css.open)
    const label = t(i18n)`User Menu`

    function toggle(): void {
        if (open) {
            dispatch({ type: 'close' })
        } else {
            dispatch({ type: 'open' })
        }
    }

    return (
        <div className={classname} ref={ref}>
            <button type='button' onClick={toggle} aria-label={label} aria-expanded={open}>
                <div className={css.bezel}>
                    <img src={avatarUrl} className={css.pic} alt={username} />
                </div>
                {open && <CaretUp aria-hidden='true' className={css.caret} />}
                {!open && <CaretDown aria-hidden='true' className={css.caret} />}
            </button>
            <div className={css.tooltip}>
                <Trans>Logged in as {username}</Trans>
            </div>
            {/* eslint-disable jsx-a11y/no-redundant-roles */}
            <ul className={css.dropdown} hidden={!open} role='list'>
                {items(username).map((item: Item | Spacer): React.ReactElement | false =>
                    item === spacer ? (
                        <hr className={css.spacer} key='spacer' aria-hidden='true' />
                    ) : (
                        (profile.isAdmin || !item.admin) && (
                            <li key={item.href}>
                                <Link href={item.href}>
                                    <item.icon className={css.icon} aria-hidden='true' />
                                    {item.title(i18n)}
                                </Link>
                            </li>
                        )
                    ),
                )}
            </ul>
        </div>
    )
}

UserUI.defaultProps = {
    loading: false,
    open: false,
}
