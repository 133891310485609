import * as React from 'react'
import classnames from 'classnames'

import { Link } from '../link'
import { UIProps } from '.'

import css from './styles.css'

export function HeaderIconUI(props: UIProps): React.ReactElement {
    const { count = -1, href, label, open, dispatch, icon: Icon, className, onClick, id, expanded = false } = props
    const classname = classnames(css.headericon, count === 0 && css.empty, className)

    function onOpen(): void {
        dispatch({ type: 'open' })
    }

    function onClose(): void {
        dispatch({ type: 'close' })
    }

    const children = (
        <>
            <Icon className={css.icon} aria-hidden='true' /> {count > 0 && count.toLocaleString()}
            <Tooltip show={open} aria-hidden='true' id={id}>
                {label}
            </Tooltip>
        </>
    )

    const p = {
        'aria-label': label,
        'aria-expanded': expanded,
        className: classname,
        onMouseEnter: onOpen,
        onMouseLeave: onClose,
        onFocus: onOpen,
        onBlur: onClose,
        onClick,
        children,
    }

    if (href) {
        return <Link href={href} {...p} />
    }

    return <button {...p} />
}

HeaderIconUI.defaultProps = {
    open: false,
}

type TooltipProps = {
    children: React.ReactNode
    show: boolean
    id: string
}

export function Tooltip(props: TooltipProps): React.ReactElement {
    const { children, show, id } = props

    return (
        <div className={css.tooltip} hidden={!show} id={id}>
            {children}
        </div>
    )
}

Tooltip.defaultProps = {
    show: false,
}
