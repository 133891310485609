import { languages, Language } from './i18n'

const locales = languages.map((language: Language): string => language.replace('-', '_')).join('|')

export function addLocale(paths: string[]): string[] {
    const res = new Set(paths)
    for (const pth of paths) {
        res.add(`/:locale(${locales})${pth}`)
    }

    return Array.from(res)
}
