import * as React from 'react'
import { config } from '../config'
import { ErrorBoundary as Boundary, Props } from '../lib/components/error-boundary'

import { type datadogRum } from '@datadog/browser-rum-slim'

declare global {
    /* eslint-disable @typescript-eslint/consistent-type-definitions */
    interface Window {
        DD_RUM?: typeof datadogRum
    }
}

export async function initialize(): Promise<void> {
    if (!config.DATADOG_APP_ID || !config.DATADOG_CLIENT_TOKEN) {
        return
    }

    const { datadogRum } = await import('@datadog/browser-rum-slim')

    datadogRum.init({
        applicationId: config.DATADOG_APP_ID,
        clientToken: config.DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'catalog-pages',
        sampleRate: config.DATADOG_RUM_SAMPLE_RATE,
        replaySampleRate: 0,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
    })

    const gtm = window.dataLayer?.find((evt) => evt.event === 'gtm.js')
    if (gtm) {
        datadogRum.setGlobalContextProperty('user_session', gtm['user.loggedIn'])
    }
}

function onError(error: Error, info?: React.ErrorInfo): void {
    if (!window.DD_RUM) {
        return
    }

    window.DD_RUM.addError(error, {
        stack: info?.componentStack,
    })
}

export function ErrorBoundary(props: Props): React.ReactElement {
    function handleError(error: Error, info?: React.ErrorInfo): void {
        onError(error, info)
        props.onError?.(error, info)
    }

    return <Boundary {...props} onError={handleError} />
}
