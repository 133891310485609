import { track } from './data-layer'

type NavigationEvent = {
    // The location the user is navigating to from the header
    location: string
}

export function trackNavigation(event: NavigationEvent): void {
    track({
        event: 'navigation',
        navigationLocation: event.location,
    })
}

type LanguageEvent = {
    // The current language
    currentLanguage: string

    // The language that was selected
    selectedLanguage: string
}

export function trackLanguage(event: LanguageEvent): void {
    track({
        event: 'language',
        ...event,
    })
}

type SearchEvent = {
    term: string
    type: string
    results: number
}

export function trackSearch(event: SearchEvent): void {
    track({
        event: 'site-search',
        searchType: event.type,
        searchTerm: event.term,
        numberSearchResults: event.results,
    })
}
