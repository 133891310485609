import { addState } from '../../add-state'
import { User as Profile } from '../../login'
import { UserUI } from './ui'

export type Props = {
    profile?: Profile
    loading?: boolean
    returnTo?: string
    tabIndex?: number
}

export type State = {
    open: boolean
}

export type Action = { type: 'open' } | { type: 'close' }

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'open':
            return { ...state, open: true }
        case 'close':
            return { ...state, open: false }
        default:
            return state
    }
}

export const initial = {
    open: false,
}

export const User = addState(UserUI, reducer, initial)
