import * as React from 'react'
import classnames from 'classnames'

import css from './styles.css'

type Props = {
    children: React.ReactNode
    className?: string
}

export function Layout(props: Props): React.ReactElement {
    const { children, className } = props
    return <div className={classnames(css.root, className)}>{children}</div>
}
