import { addState } from '../../add-state'

import { HeaderDropdownUI } from './ui'
import { Category } from './types'

export type State = {
    category: number | null
    mobileOpen: boolean
}

export type Action =
    | { type: 'open'; category: number }
    | { type: 'close' }
    | { type: 'close-all' }
    | { type: 'focus'; category: number }
    | { type: 'mobile-toggle' }

export type Props = {
    categories: Category[]
}

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'open':
            return { ...state, category: action.category }
        case 'close':
            return { ...state, category: null }
        case 'focus': {
            if (state.category === null) {
                return state
            }
            return { ...state, category: action.category }
        }
        case 'mobile-toggle':
            return { ...state, mobileOpen: !state.mobileOpen }
        case 'close-all':
            return { ...state, mobileOpen: false, category: null }
        default:
            return state
    }
}

const initial = {
    category: null,
    mobileOpen: false,
}

export const HeaderDropdown = addState(HeaderDropdownUI, reducer, initial)
