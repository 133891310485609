import * as React from 'react'
import css from './use-block-scroll.css'

type Options = {
    block?: boolean
}

export function useBlockScroll(options: Options = {}): void {
    const { block = true } = options

    React.useEffect(
        function () {
            const el = document.body
            if (block) {
                el.classList.add(css.block)
            } else {
                el.classList.remove(css.block)
            }

            return (): void => el.classList.remove(css.block)
        },
        [block],
    )
}
